import { Ctrl } from ":mods";
import { ROUTES } from "../const";
import { ShowCourses, ShowStudentStage, ShowSubmodule } from "../views";
import { $selected_module } from "../store";
import { Route } from "@solidjs/router";

export function StudentRoutes(props: Ctrl.Routes.model.StudentView) {
  return (
    <>
      <Route path={ROUTES.student.all_courses} element={<ShowCourses {...props} />} />
      {/* <Route
        path={ROUTES.student.courses.foundation()}
        element={<div>welocme to student dashboard, please select a module to start or continue your journey</div>}
      /> */}
      <Route
        path={ROUTES.side.courses.foundation()}
        element={
          <div class="flex flex-col w-full h-full items-center justify-center">
            <i class="icon-local:logo !w-full !h-50%" />
            <span class="font-bold capitalize tex-14px">your journey with museums starts here</span>
            {/* <span class="font-semibold capitalize tex-14px">click on curse materials to start</span> */}
          </div>
        }
      />
      <Route
        path={ROUTES.side.courses.foundation_module()}
        element={<ShowStudentStage {...props} details={$selected_module.value} />}
      />
      <Route
        path={[ROUTES.side.courses.foundation_submodule(), ROUTES.side.courses.advanced_submodule()]}
        element={<ShowSubmodule {...props} />}
      />
    </>
  );
}
